import * as React from 'react';
import Typography from '@mui/material/Typography';
import './App.css'
import Logo from './UI/components/assets/logo.png'
import Programas from './UI/components/assets/programas.jpg'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';


function App() {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="App">
    <div></div>
    <img src={Logo} className="logo" alt="logo"/>
      <Typography variant="overline" className="legend">
        Sitio web en construcción
      </Typography>
      <Button variant="contained" onClick={handleOpen}>Programas y líneas de acción</Button>
      <Modal
        open={open}
        onClose={handleClose}
       
      >
       
      <img src={Programas} className="programas" alt="logo"/>
      </Modal>

    </div>
  );
}

export default App;
